<template>
  <v-sheet height="100%" width="100%">
    <v-navigation-drawer v-model="dialog_plates" fixed left width="450px" app>
      <v-toolbar color="#CFD8DC" class="fixed-bar">
        <v-toolbar-title>Platos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="dialog_plates = !dialog_plates">mdi-arrow-left</v-icon>
      </v-toolbar>

      <v-data-table
        :headers="header_items.true"
        :items="items"
        :items-per-page="-1"
        item-key="code"
        sort-by="name"
        :search="search_plate"
        mobile-breakpoint="0"
        class="table-cursor elevation-1"
        loading-text="Cargando ..."
        @click:row="select_plate"
      >
        <template v-slot:[`item.picture`]="{ item }" class="pa-0 ma-0">
          <v-avatar>
            <v-img
              v-if="item.picture"
              :src="getImagePhoto(item.account, item.store, item.picture)"
            ></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.product_name`]="{ item }" class="pa-0 ma-0">
          <b>{{ item.product_name }}</b>
          <small v-html="item.description" />
        </template>
      </v-data-table>
    </v-navigation-drawer>

    <v-container class="pt-12">
      <v-app-bar flat color="transparent" width="100%" class="fixed-bar-b">
        <v-icon large @click="dialog_plates = !dialog_plates"
          >mdi-room-service-outline
        </v-icon>
        <v-spacer></v-spacer>
        <v-icon
          v-if="plate_selected.code"
          large
          @click="dialog_smart = !dialog_smart"
          >mdi-plus
        </v-icon>
      </v-app-bar>

      <v-card class="mt-6">
        <v-card-title>
          <v-list>
            <v-list-item three-line>
              <v-list-item-avatar size="80"
                ><v-img
                  v-if="plate_selected.picture"
                  :src="
                    getImagePhoto(
                      plate_selected.account,
                      plate_selected.store,
                      plate_selected.picture
                    )
                  "
                ></v-img
              ></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-text="plate_selected.product_name"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="plate_selected.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-title>
        <v-card-text>
          <v-snackbar v-model="snackbar" dark color="red" timeout="2000">
            {{ text }}
          </v-snackbar>
          <v-data-table
            :headers="header_cost"
            :items="items_cost"
            mobile-breakpoint="0"
            :items-per-page="-1"
            :loading="loading_status"
          >
            <template v-slot:[`item.qty`]="props">
              <v-edit-dialog
                :return-value.sync="props.item.qty"
                @save="save"
                @cancel="cancel"
                @open="open"
                @close="close"
              >
                {{ props.item.qty }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.qty"
                    :suffix="props.item.uom"
                    label="Edit"
                    single-line
                    clearable
                  />
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ item.qty * item.price }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="8" md="8">Total Cost of Dish</v-col>
            <v-col cols="4" md="4" align="end">{{
              this.totals.cost.toLocaleString(2)
            }}</v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>

    <v-navigation-drawer
      v-model="dialog_smart"
      fixed
      right
      :width="smart_width"
      app
    >
      <v-toolbar color="#CFD8DC" class="fixed-bar">
        <v-btn x-small fab class="mr-2" @click="s_expand = !s_expand">
          <v-icon>mdi-arrow-split-vertical</v-icon>
        </v-btn>
        <v-toolbar-title>Smart List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="dialog_smart = !dialog_smart">mdi-arrow-right</v-icon>
      </v-toolbar>
      <v-toolbar color="#CFD8DC" class="fixed-bar-b">
        <v-select
          rounded
          :items="smart_categories"
          label="Categoría"
          outlined
          dense
          hide-details="auto"
          @change="smart_category"
        />
        <v-spacer></v-spacer>
        <v-btn x-small color="warning" fab @click="newRaw()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="header_raw[!s_expand]"
        :items="smart_lst_selected"
        :items-per-page="15"
        item-key="code"
        sort-by="name"
        :search="search_smart"
        mobile-breakpoint="0"
        class="table-cursor elevation-1"
        loading-text="Cargando ..."
        @click:row="add_to_costs"
      >
        <template v-slot:[`item.picture`]="{ item }" v-if="item.picture !== ''">
          <v-avatar v-if="item.picture">
            <v-img
              :src="getImagePhoto(item.account, item.store, item.picture)"
            ></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.name`]="{ item }" class="pa-0 ma-0">
          <b>{{ item.product_name }}</b>
          <span v-if="$vuetify.breakpoint.xsOnly">
            <small> ({{ item.category }})</small><br />
            <small> {{ item.size }} {{ item.uom }} </small>
          </span>
        </template>
        <template v-slot:[`item.size`]="{ item }">
          {{ item.size + " " + item.uom }} <br />
        </template>

        <template v-slot:[`item.price`]="{ item }">
          {{ "$ " + parseFloat(item.price).toLocaleString(2) }}
        </template>
      </v-data-table>

      <v-bottom-navigation app>
        <v-toolbar color="#CFD8DC">
          <v-row justify="center">
            <v-col md="12" cols="12">
              <v-text-field
                v-model="search_smart"
                hide-details
                append-icon="mdi-magnify"
                outlined
                rounded
                dense
                placeholder="Buscar producto Smart ..."
                @keypress="update_selected()"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </v-bottom-navigation>
    </v-navigation-drawer>

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
    </v-dialog>

    <RawForm
      table="raw_material"
      :item="raw_item"
      :dialog="raw_dialog"
      :newItem="newRawItem"
      :store="store.code"
      @close="raw_dialog = false"
      :categories="smart_categories"
      @refresh="refresh_raw()"
    />
  </v-sheet>
  <!-- :subcategories="subcategories"
      :brands="brands"
      :suppliers="this.supplier_selected.code" -->
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import RawForm from "../components/RawMaterialForm.vue";
export default {
  components: { RawForm },
  data() {
    return {
      loading_status: false,
      dialog_plates: true,
      raw_dialog: false,
      items: [],
      items_cost: [],
      header_items: {
        false: [
          {
            text: "",
            align: "start",
            value: "picture",
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },

          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "price",
            dataType: "number",
          },
        ],
        true: [
          {
            text: "",
            align: "start",
            value: "picture",
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },

          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "price",
            dataType: "number",
          },
        ],
      },
      header_cost: [
        {
          text: "Item",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },

        {
          text: "Qty",
          align: "start",
          sortable: true,
          value: "qty",
          dataType: "text",
        },
        {
          text: "UOM",
          align: "start",
          sortable: true,
          value: "normalized_uom",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
          dataType: "text",
        },
        { text: "", value: "actions", sortable: false },
      ],
      header_raw: {
        false: [
          {
            text: "",
            align: "center",
            value: "picture",
            width: 50,
          },
          {
            text: "Categoría",
            align: "start",
            sortable: true,
            value: "category",
            dataType: "text",
          },
          {
            text: "Marca",
            align: "start",
            sortable: true,
            value: "brand",
            dataType: "text",
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Tamaño",
            align: "center",
            sortable: true,
            value: "size",
            dataType: "text",
          },
          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "price",
            dataType: "number",
          },
        ],
        true: [
          {
            text: "",
            align: "center",
            value: "picture",
            width: 50,
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Valor",
            align: "end",
            sortable: true,
            value: "price",
            dataType: "number",
          },
        ],
      },
      search_plate: "",
      plate_selected: createItem(),
      search_smart: "",
      s_expand: false,
      smart_width: 450,
      dialog_smart: false,
      smart_categories: [],
      smart_lst_selected: [],
      item: createItem(),
      raw_item: createItem(),
      snackbar: false,
      text: `Hello, I'm a snackbar`,
      totals: {
        price: 0,
        cost: 0,
        rate: 0,
        market: 0,
      },
      newRawItem: false,
      store: "",
    };
  },
  mounted() {
    this.store = this.$store.getters.company;
    this.get_items();
    this.get_smart_lst();
  },
  methods: {
    get_items() {
      this.items = [];
      this.loading_status = true;
      var qry = {
        account: this.store.account,
        table: "catalogue",
        filters: [{ field: "store", operator: "=", value: this.store.code }],
      };
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        this.items = data;
        this.loading_status = false;
      });
    },
    get_smart_lst() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "raw_material",
        filters: [
          // {
          //   field: "account",
          //   operator: "=",
          //   value: this.$store.state.profile.account,
          // },
          { field: "store", operator: "=", value: "000000" },
        ],
      };
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        var ctgry = data.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));

        for (const [key] of Object.entries(ctgry)) {
          ctgry[key].sort(function (a, b) {
            var textA = a.product_name.toUpperCase();
            var textB = b.product_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }
        this.smart_ctgry = ctgry;
        this.smart_categories = Object.keys(ctgry).sort();
        this.smart_lst_selected = data;

        this.loading_status = false;
        this.smart_lst = data;
      });
    },
    smart_category(e) {
      this.search_smart = "";
      this.smart_lst_selected = this.smart_ctgry[e];
      console.log(this.smart_lst_selected, this.smart_ctgry, e);
    },
    add_to_costs(e) {
      this.snackbar = true;
      this.text = e.product_name;
      e.qty = 1;
      this.totals.price = e.price;

      this.items_cost.push({ ...e });
      var helper = {};
      this.items_cost = this.items_cost.reduce((r, o) => {
        var key = o.code;
        if (!helper[key]) {
          helper[key] = Object.assign({}, o); // create a copy of o
          helper[key].qty = parseFloat(helper[key].qty);
          helper[key].cost =
            parseFloat(helper[key].qty) * parseFloat(helper[key].cost_uom);
          r.push(helper[key]);
        } else {
          helper[key].qty += parseFloat(o.qty);
          helper[key].cost =
            parseFloat(helper[key].qty) * parseFloat(o.cost_uom);
        }
        return r;
      }, []);
      this.costs_calculation();
    },
    costs_calculation() {
      this.totals.cost = 0;
      //this.totals.price = this.product.price;
      this.items_cost.forEach((x) => {
        this.totals.cost += parseFloat(x.price);
      });
    },
    editItem(e) {
      this.qtyChange = e.qty;
      this.item = e;
      this.dialogQty = true;
    },
    deleteItem(e) {
      var index = this.items_cost.indexOf(e);
      if (index !== -1) {
        this.items_cost.splice(index, 1);
      }
      //e.qty = 0;
    },
    select_plate(e) {
      this.plate_selected = { ...e };
      this.dialog_plates = false;
      this.dialog_smart = true;
    },
    newRaw() {
      this.raw_item = createItem();
      this.store = "000000";
      this.raw_item.account = this.$store.state.profile.account;
      this.raw_item.store = "000000";
      this.raw_item.supplier = "SCP";
      this.raw_valid = false;
      this.newRawItem = true;
      this.raw_dialog = true;
    },
    refresh_raw() {
      this.raw_dialog = false;
      if (this.newRawItem) {
        this.get_smart_lst();
      }
    },
    getImagePhoto: function (account, store, src) {
      var url =
        "https://smartchef.pro/photos/" + account + "/" + store + "/" + src;
      return url;
    },
  },
  watch: {
    items_cost: function () {
      this.costs_calculation();
    },
  },
};
</script>

<style>
.fixed-bar-b {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 56px;
  z-index: 2;
}
</style>